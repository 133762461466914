body, html {
  background-color: #470F47 !important;
}

.App {
  text-align: center;
  min-height: 100% !important;
}

.navbar-link.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, a.navbar-item.is-active, a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover {
  background: transparent !important;
  text-decoration: none !important;
}

.navbar {
  height: 80px;
  background-color: #009DDC !important;
  border-bottom: 7px solid #017fb1;
  border-left: 7px solid #017fb1;
  box-shadow: 5px 7px #005e83;
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
}

.mod-size {
  height: 70px;
}

.dialog-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.4)
}

.dialog-wrapper.hidden {
  display: none;
}

.nes-dialog {
  z-index: 99;
  position: absolute;
  top: 50%;
  margin-top: -109px;
  background: #fff;
  border: 5px solid #000000;
  text-align: center;
  left: 50%;
  margin-left: -300px;
  width: 600px;
}

.nes-dialog .nes-btn {
  margin-left: 0;
}


.nes-icon.big {
  transform: scale(4);
}

.nes-icon.medium {
  transform: scale(2);
  margin: 0 !important;
}

.nes-container {
  background-color: #fff !important;
}

.nes-dialog .dialog-menu {
  padding: 0;
}

.nes-dialog * {
  text-align: center;
}

.Splash {
  background-image: url('./assets/background.jpg');
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.Explore, .Login, .Details .Marketplace {
  background-color: #470F47;
}

.Details {
  min-height: 82vh;
}

.login-container, .dashboard-container {
 min-height: 93vh !important;
}

.selling-box {
  background-color: rgba(238, 88, 88, 0.35)!important;
  position: relative !important;
}

.dashboard-box {
  padding: 60px 30px !important;
  height: 220px;
}

.myButton {
  width: 150px;
  height: 80px !important;
  background-color: #005D7F !important;
}

#scrypta-login button, #confirm-button {
  width: 80% !important;
  height: 100px;
  font-size: 30px;
  font-weight: 600;
  background-color: #009DDC !important;
  border-bottom: 10px solid #017fb1 !important;
  border-right: 10px solid #017fb1!important;
  font-family: 'Press Start 2P', cursive !important;
}

#scrypta-login-wrapper {
  z-index: 9999;
}

#scrypta-login-contents {
  padding: 16vh 60px !important;
}

#scrypta-login-contents p {
  text-align: center;
}

.header-color {
  background-color: #009DDC !important;
  border-bottom: 10px solid #017fb1 !important;
  border-right: 10px solid #017fb1!important;
}

.nes-container .title {
  font-size: 22px;
  line-height: 5px!important;
  color: rgb(228, 46, 73) !important;
}

.header-color h1.title, .header-color2 h1.title {
  margin-top: 15px;
  color: rgb(255, 255, 255) !important;
}

.header-color2 {
  background-color: rgb(228, 46, 73) !important;
  border-bottom: 10px solid rgb(176, 35, 56) !important;
  border-right: 10px solid rgb(176, 35, 56) !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

p, ul, li {
  text-align: left;
  font-size: 14px;
}

ul>li {
  list-style-type: circle;
}

pre {
  width: 50%;
  height: auto;
  margin-bottom: 30px;
  border-radius: 5px;
}

.field {
  width: 50%!important;
}

.App-header {
  background: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  body {
    font-size: 8px !important;
    overflow-x: hidden;
  }
  .navbar-burger {
    display: none !important;
  }
  .navbar-item {
    margin: 15px 0px;
  }
  h1 {
    font-size: 14px !important;
  }
  p {
    font-size: 12px !important;
  }
  .navbar-item h1 {
    font-size: 10px !important;
  }
  .box {
    margin: 0 20px !important;
  }
  .nes-container.with-title>.title {
    font-size: 12px !important;
  }
  .nes-container .title {
    width: 100%;
    line-height: 5px;
    color: rgb(228, 46, 73);
  }
  .nes-container {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
  .box.nes-container {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    height: 120px;
  }
  .header-color, .header-color2 {
    height: 30px !important;
    padding: 10px 0 !important
  }
  .box.header-color h1, .box.header-color2 h1 {
    font-size: 12px !important;
    margin: 5px 0 !important;
  }
  .nes-container {
    padding-left: 5px !important;
    padding-right: 5px !important;
    word-break: break-all;
  }
  .nes-container .is-rounded .with-title {
    width: 100% !important;
  }
  .nes-btn {
    font-size: 8px !important;
  }
  input, b, strong {
    font-size: 8px !important;
  }
  .nes-field {
    padding: 0 15px !important;
  }
  .nes-input, .nes-btn {
    height: 40px !important;
  }
  .nes-container.with-title.mobile-sell {
    height: 180px !important;
  }
  .nes-container .mobile-btn {
    width: 85%;
    top: 125px !important;
  }
  .nes-container .mobile-btn-owned {
    width: 85%;
    top: 50px !important;
    right: 20px !important;
  }
  .mobile-owned {
    height: 110px;
  }
  .nes-dialog {
    z-index: 99;
    position: absolute;
    top: 50%;
    margin-top: -109px;
    background: #fff;
    border: 5px solid #000000;
    text-align: center;
    left: 50%;
    margin-left: -180px;
    width: 360px;
  }
  .explore-btn {
    top: 0;
    right: 10px;
  }
  .explorer-title {
    margin-bottom: 30px !important;
    height: 60px !important;
  }
  .box.header-color.explorer-title .title {
    line-height: 18px !important;
    word-spacing: 0px;
    padding: -10px;
    margin: 0 !important;
  }
  .nes-container.explorer-container-sell {
    height: 120px;
  }
}